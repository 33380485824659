<template>
  <div class="noroot" style="background:#f9f9f9">
    <div
      style="
        height: 100px;
        padding-top: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      "
    >
      <div style="margin-right: 240px">
        <router-link
          to="/"
          class="home"
          style="display: flex; flex-direction: row"
        >
          <img
            :src="require('../assets/logo.jpg')"
            class="logo"
            style="width: 50px"
          />
          <span style="color: #000">
            <h3>中医临床药学服务<br />与调剂教育平台</h3>
          </span>
        </router-link>
      </div>
      <div
        style="
          width: 240px;
          height: 40px;
          text-align: center;
          line-height: 40px;
        "
      >
        已有账号？<router-link to="/login" style="color: #ff9552"
          >立即登录</router-link
        >&nbsp;&nbsp;|&nbsp;&nbsp;<router-link to="/" style="color: #ff9552"
          >返回首页</router-link
        >
      </div>
    </div>
    <div class="wrap" style="position: relative">
      <!-- 找回密码 -->
      <div class="modal" v-if="primary">
        <div class="title">账号安全</div>
        <div class="telModal" >
          <span class="modal-title">
            <p>找回密码</p>
          </span>
          <div class="tel-err" v-if="telErr">
            <img :src="telError" class="telErr" />
            手机号格式错误
          </div>
          <div class="tel-inputTel">
            <div class="tel-tel">
              <img :src="iphone" class="iphone" />
              <input
                id="iPhone"
                type="text"
                placeholder="请输入手机号"
                v-model="userTel"
                autocomplete="off"
                @focus="highLight"
                @blur="userBlur"
              />
            </div>
          </div>
          <div
            class="codeErr"
            v-if="resErr"
            style="color: #2c2c2c; width: 250px; top: 47%; left: 38.4%"
          >
            该手机号未注册，你可以
            <router-link to="/register" style="color: #ff9552"
              >去注册</router-link
            >
          </div>
          <!-- 滑块 -->
          <div id="drag">
            <div class="drag_bg"></div>
            <div
              class="drag_text"
              onselectstart="return false;"
              unselectable="on"
            >
              请拖动滑块，拖到最右边
            </div>
            <div class="handler handler_bg"></div>
          </div>
          <!-- 获取验证码 -->
          <div
            class="verity"
            v-if="dragStatus && !resErr && !telErr && userTel.length > 10"
          >
            <div class="verity-num">
              <input
                type="text"
                placeholder="请输入验证码"
                v-model="validCode"
                autocomplete="off"
                @focus="highLight"
                @blur="normal"
              />
            </div>
            <div>
              <button @click="getCode" :disabled="disabled" class="verity-btn">
                {{ text }}
              </button>
            </div>
          </div>
          <div class="codeErr" v-if="codeErr" style="width: 100%">
            {{ errMsg }}
          </div>
          <button
            @click="login"
            type="button"
            :style="`background:${
              btnStatus ? '#ddd' : '#FF9552'
            };letter-spacing: 2px;margin-bottom:80px;cursor:pointer`"
            :disabled="btnStatus"
          >
            下一步
          </button>
        </div>
      </div>
      <!-- 设置密码 -->
      <div class="modal" v-if="second">
        <div class="title">账号安全</div>
        <div class="telModal">
          <span class="modal-title">
            <p>设置密码</p>
          </span>
          <div class="tel-inputTel">
            <div class="tel-tel">
              <input
                id="pwd1"
                type="password"
                placeholder="请设置密码"
                v-model="pwd"
                autocomplete="off"
                maxlength="16"
                @keyup="checkPwd"
                @focus="highLight"
                @blur="normal"
              />
            </div>
          </div>
          <div class="tel-inputTel">
            <div class="tel-tel">
              <input
                id="pwd2"
                type="password"
                placeholder="请确认密码"
                v-model="rePwd"
                autocomplete="off"
                maxlength="16"
                @keyup="checkPwd"
                @focus="highLight"
                @blur="normal"
              />
            </div>
          </div>
          <div
            class="codeErr"
            v-if="pwdErr"
            style="width: 250px; top: 65%; left: 38.4%"
          >
            两次密码输入不一致！
          </div>
          <div
            class="codeErr"
            v-if="pwdSame"
            style="color: #1fe32c; width: 250px; top: 65%; left: 38.4%"
          >
            密码一致
          </div>
          <button
            @click="nextStep"
            type="button"
            :style="`background:${
              btnStep ? '#ddd' : '#FF9552'
            };letter-spacing: 2px;margin-bottom:80px;cursor:pointer`"
            :disabled="btnStep"
          >
            下一步
          </button>
        </div>
      </div>
      <!-- 设置成功 -->
      <div class="modal" v-if="third">
        <div class="title">账号安全</div>
        <div class="telModal">
          <span class="modal-title">
            <p>找回密码</p>
          </span>
          <div
            style="
              width: 288px;
              height: 50px;
              margin: 0 auto;
              margin-top: 150px;
            "
          >
            您已经成功设置密码，请使用新密码登录
          </div>
          <router-link to="/login"
            ><button style="letter-spacing: 2px; margin-bottom: 80px;cursor:pointer">
              马上登录
            </button></router-link
          >
        </div>
      </div>
    </div>
    <!-- Warning -->
    <div v-if="warningMsg" class="div-warn">
      <div class="warning">
        <span>{{ msg }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from "vue";
import { post } from "@/utils/common";
import { save } from "@/utils/storage";
import router from "@/router";
import { initLogindata } from "@/utils/common";

export default {
  name: "RetrievePwd",
  components: {},
  setup(props) {
    const state = reactive({
      resizetime: null,
      // login: require("../assets/login.jpg"),
      logo: require("../assets/logo3.png"),
      user: require("../assets/user.png"),
      pwd: require("../assets/pwd.png"),
      tel: require("../assets/tel.png"),
      user2: require("../assets/user2.png"),
      iphone: require("../assets/iphone.png"),
      telError: require("../assets/telErr.png"),
      verity1: require("../assets/verity1.png"),
      verity2: require("../assets/verity2.png"),
      eyeClose: require("../assets/eyeClose.png"),
      eyeOpen: require("../assets/eyeOpen.png"),

      warningMsg: false,
      warning: false,
      telPage: true,
      primary: true,
      second: false,
      third: false,
      telErr: false,
      btnStatus: true,
      btnStep: true,
      unRegister: false,
      resErr: false,
      orErr: false,
      userTel: "",
      validCode: "",
      username: "",
      password: "",
      pwd: "",
      rePwd: "",
      errMsg: "",
      codeErr: false,
      dragStatus: false,
      msg: "",
      text: "发送验证码",
      disabled: false,
      imgShow: true,
      dragCode: false,
      pwdErr: false,
      pwdSame: false,
      pwdMsg: "",
    });

    onMounted(() => {
      console.log("111");
      drag();
    });

    watch(
      () => state.warningMsg,
      (newState) => {
        console.log("newState", newState);
      }
    );

    //获取验证码
    const getCode = () => {
      post(
        "getVCode",
        { userTel: state.userTel },
        (_, res) => {
          state.disabled = true;
          let num = 60;
          state.text = `${num}s后重新获取`;
          let timer = setInterval(() => {
            num--;
            if (num > -1) {
              state.text = `${num}s后重新获取`;
            } else {
              clearInterval(timer);
              state.disabled = false;
              state.text = "获取验证码";
            }
          }, 1000);
          if (state.dragStatus && !state.resErr && !state.telErr) {
            state.btnStatus = false;
          }
        },
        (err) => {
          if (err) {
            msgModal(err)
            return;
          }
        }
      );
    };

    // 判断手机号格式&&判断手机号是否注册
    const userBlur = () => {
      var phone = document.getElementById("iPhone");
      var str =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (state.userTel.length ) {
        if (str.test(phone.value) == false) {
          state.telErr = true;
          state.btnStatus = true;
          state.resErr = false;
        } else {
          state.telErr = false;
          // state.btnStatus = false;
          post("checkRegister", { username: state.userTel }, (_, res) => {
            if (res.body.feedbackMsg == false) {
              state.resErr = true;
              state.btnStatus = true;
            } else {
              state.resErr = false;
              // state.btnStatus =false;
            }
          });
        }
      }
    };

    const msgModal = (msg) =>{
      state.warningMsg = true;
      state.msg = msg;
      setTimeout(function () {
        state.warningMsg = false;
      }, 2000);
    }

    // 找回密码
    const login = () => {
      // 手机号和验证码非空判断
      if(!state.userTel.length){
          msgModal("请填写手机号")
          return;
      }
      if(!state.validCode.length){
          msgModal("请填写验证码")
          return;
      }
     
      //滑块是否通过
      if (!state.dragStatus) {
        msgModal("请验证滑块")
        return;
      }

        post(
          "authByVCode",
          {
            userTel: state.userTel,
            validCode: state.validCode,
          },
          (_, res) => {
            initLogindata(res.body);
            // console.log("dadadada", res.body.token.body);
            state.primary = false;
            state.second = true;
          },
          (err) => {
            console.log("错误信息", err);
            //验证码是否正确
            if (err) {
              state.errMsg = err;
              state.codeErr = true;
              return;
            } else {
              state.codeErr = false;
            }
          }
        );
      
      //   if(!state.validCode.length){
      //     //   state.codeErr = true;
      //     return;
      //   }
    };

    // 滑块
    const drag = () => {
      //   console.log("telPagetelPagetelPage222");
      //   if (!state.telPage) return;
      //   console.log("telPagetelPagetelPage555");
      var x,
        dragdom = document.getElementById("drag"),
        isMove = false;
      var handler = dragdom.getElementsByClassName("handler")[0];
      var drag_bg = dragdom.getElementsByClassName("drag_bg")[0];
      var text = dragdom.getElementsByClassName("drag_text")[0];
      var maxWidth = (dragdom.clientWidth || 0) - (handler.clientWidth || 0); //能滑动的最大间距

      //鼠标按下时候的x轴的位置
      handler.addEventListener("mousedown", function (e) {
        console.log("mousedown");
        if (text.innerHTML === "验证通过") return;
        isMove = true;
        x = e.pageX - parseInt(handler.style.left || 0, 10);
      });

      //鼠标指针在上下文移动时，移动距离大于0小于最大间距，滑块x轴位置等于鼠标移动距离
      document.addEventListener("mousemove", function (e) {
        if (isMove) {
          var _x = e.pageX - x;
          if (_x > 0 && _x <= maxWidth) {
            handler.style.left = `${_x}px`;
            drag_bg.style.width = `${_x}px`;
          } else if (_x > maxWidth) {
            //鼠标指针移动距离达到最大时清空事件
            handler.style.left = `${maxWidth}px`; //当鼠标加速时把移动条置满
            drag_bg.style.width = `${maxWidth}px`;
            state.dragStatus = true;
            dragOk();
          }
        }
      });
      document.addEventListener("mouseup", function (e) {
        if (isMove) {
          isMove = false;
          var _x = e.pageX - x;
          if (_x < maxWidth) {
            //鼠标松开时，如果没有达到最大距离位置，滑块就返回初始位置
            handler.style.left = `0px`;
            drag_bg.style.width = `0px`;
          } else {
          }
        }
      });

      //清空事件
      function dragOk() {
        isMove = false;
        handler.classList.remove("handler_bg");
        handler.classList.add("handler_ok_bg");
        text.innerHTML = "验证通过";
        dragdom.style.color = `#fff`;
        handler.removeEventListener("mousedown", drag, false);
        document.removeEventListener("mousemove", drag, false);
        document.removeEventListener("mouseup", drag, false);
      }
    };

    //判断两次输入密码是否一致
    const checkPwd = () => {
      const { pwd, rePwd } = state;
      if (pwd.length  && rePwd.length && pwd == rePwd) {
        state.pwdSame = true;
        state.pwdErr = false;
        state.btnStep = false;
      } else {
        state.pwdErr = true;
        state.pwdSame = false;
        state.btnStep = true;
      }
    };

    //设置密码
    const nextStep = () => {
      const { pwd, rePwd } = state;
      if (state.second) {
        if ( pwd.length < 6 || rePwd.length < 6 ) {
          msgModal("密码长度不得小于6位或大于16位")
          state.btnStep = true;
          save("logindata", null);
          return;
        }
      }
      post(
        "appUser/resetPassword",
        {
          userTel: state.userTel,
          validCode: state.validCode,
          password: state.rePwd,
        },
        (_, res) => {
          // initLogindata(res.body.token.body);
          state.third = true;
          state.primary = false;
          state.second = false;
          save("logindata", null);
        }
      );
    };

    const highLight = (e) => {
      // console.log("highLight ==", e);
      // e.target.parentNode.style.border = `1px solid rgb(255, 149, 82,0.5)`;
      // e.target.parentNode.style.boxShadow = `#ff9552 0px 0px 4px 1px`;
    };

    const normal = (e) => {
      // e.target.parentNode.style.border = `2px solid #eee`;
      // e.target.parentNode.style.boxShadow = `none`;
    };

    return {
      ...toRefs(state),
      drag,
      getCode,
      login,
      userBlur,
      checkPwd,
      nextStep,
      highLight,
      normal,
    };
  },
};
</script>


<style lang="less" scoped>
//空白背景
.noroot {
  height: 960px;
  position: relative;
}
.title {
  width: 700px;
  background: #ff9552;
  height: 70px;
  color: #fff;
  text-align: center;
  line-height: 70px;
  font-size: 20px;
  letter-spacing: 2px;
}

.modal {
  width: 700px;
  height: auto;
  //   position: absolute;
  background: #fff;
  //   right: 0px;
  //   top: 0px;
  margin: 0 auto;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-title {
  margin: 5%;
  text-align: center;
  font-size: 20px;
}

.input-info,
button,
.tel-inputTel,
#drag,
.verity,
.pwd {
  border-radius: 4px;
  margin-left: 208px;
  font-size: 14px;
}

.input-info,
.pwd {
  border: 2px solid #eee;
  width: 280px;
  height: 36px;
  margin-bottom: 40px;
}

.input-info > input {
  // border: 2px solid #ddd;
  margin-left: 2%;
  height: 36px;
  width: 240px;
}

button {
  width: 284px;
  height: 36px;
  color: #fff;
  background: #ff9552;
}

.user {
  width: 18px;
  height: 18px;
  margin: 5px;
  opacity: 0.7;
}

.pwd {
  width: 30px;
  height: 30px;
}

.forget-pwd {
  color: grey;
  font-size: 14px;
  display: block;
  margin: 15px 0px 15px 320px;
}

.hr {
  color: grey;
  border: 0;
  font-size: 14px;
  padding: 10px 0;
  position: relative;
  margin-top: 15px;
}

.hr::before {
  content: "其他登录方式";
  position: absolute;
  padding: 0 10px;
  line-height: 2px;
  border: solid #eee;
  border-width: 0 170px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.option {
  display: flex;
  flex-direction: row;
  margin: 30px 146px;
  font-size: 14px;
}

.tel,
.user2 {
  width: 30px;
  height: 30px;
  margin-right: 9px;
}

.mid {
  border-right: 2px solid #eee;
  margin: 0 32px;
}
.left {
  margin-right: 3px;
}

.footer {
  background: #eee;
  height: 25px;
  font-size: 14px;
  color: grey;
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  margin-top: 25px;
}

.div-checked {
  // font-size: 12px;
  margin-right: 20px;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-left: 20px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 18px;
  position: relative;
}

input[type="checkbox"]:checked::before {
  content: "\2714";
  background: #ff9552;
  color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 4px;
}

.isHas {
  padding-left: 25px;
}
router-link:hover {
  color: #ff9552;
}

// 手机号登录
.iphone {
  width: 25px;
  margin: 0 5px;
}
.tel-inputTel {
  display: flex;
  font-display: row;
}
.tel-tip,
.tel-tel,
.verity-num {
  border: 2px solid #eee;
  // width: 300px;
  height: 36px;
  margin-bottom: 40px;
  > input {
    margin-left: 5px;
    height: 36px;
  }
}
.tel-tip {
  width: 60px;
  margin-right: 10px;
  > input {
    margin: 0;
    width: 60px;
  }
}
.tel-tel {
  width: 280px;
  > input {
    width: 240px;
  }
}
//滑块
#drag {
  position: relative;
  background: #e8e8e8;
  width: 284px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 40px;
}
#drag .handler {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 37px;
  border: 1px solid #ccc;
  cursor: move;
}

.handler_bg {
  background: #fff url("../assets/dragR.png") no-repeat;
  background-size: 25px;
  background-position: 50% 50%;
}

.handler_ok_bg {
  background: #fff url("../assets/drag1.png") no-repeat;
  background-size: 30px;
  background-position: 50% 50%;
}

#drag .drag_bg {
  background: #7ac23c;
  height: 38px;
  width: 0px;
}

#drag .drag_text {
  position: absolute;
  top: 0px;
  width: 284px;
  user-select: none;
}
//验证码
.verity {
  display: flex;
  margin-bottom: 40px;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
}
.verity-btn {
  width: 100px;
  margin-left: 10px;
  height: 36px;
  background: #ff9552;
  cursor: pointer;
}
.verity-num {
  margin-bottom: 0px;
  width: 170px;
  > input {
    width: 160px;
  }
}
// .verity-img {
//   // border: #ccc 1px solid;
//   width: 34px;
//   height: 34px;
//   margin-left: 8px;
//   // background-image: url("../assets/verity1.png");
//   background-size: 30px;
//   background-position: 50% 50%;
// }
//warnijng
.div-warn {
  position: absolute;
  width: 280px;
  height: 4vh;
  top: 36%;
  left: 38.5%;
  color: #fff;
  font-size: 14px;
  background: #000;
  opacity: 0.7;
  z-index: 9900;
  border-radius: 4px;
  text-align: center;
  padding: 4vh 1vw;
}
//telErr
.tel-err {
  border: 2px solid rgb(247, 118, 118);
  width: 280px;
  height: 34px;
  margin: 0 0 15px 208px;
  border-radius: 4px;
  font-size: 14px;
  background: rgb(255, 218, 218);
  line-height: 2rem;
  // opacity: 0.7;
}
.telErr {
  width: 20px;
  margin: 0 5px;
}
//codeErr
.codeErr {
  font-size: 14px;
  color: red;
  width: 70px;
  height: 16px;
  position: absolute;
  top: 70%;
  left: 38.4%;
}
.copy:hover {
  color: #ff9552;
}
.privacy:hover {
  color: #ff9552;
}
//设置密码
.pwdImg {
  width: 20px;
}
.pwd {
  height: 36px;
  width: 280px;
  margin-left: 208px;
  > input {
    margin-left: 5px;
    width: 245px;
    height: 36px;
  }
}
</style>